import connect from '@vkontakte/vk-connect';

export function VKWebAppClose() {
	connect.send("VKWebAppClose", { "status": "success" });
}

//подписывается на обновления VKWebAppViewHide
export function listenVKWebAppViewHide(successCallback = () => {}) {
	// подписываемся на VKWebAppViewHide
	connect.subscribe((e) => {
		switch (e.detail.type) {
			case 'VKWebAppViewHide':
				successCallback(true)
			break;
			default:
				//
		}
	});
}

//включаем возможность свайпнуть назад для iOS
export function enableSwipeBack() {
	connect.send("VKWebAppEnableSwipeBack", {});
}

//выключаем возможность свайпнуть назад для iOS
export function disableSwipeBack() {
	connect.send("VKWebAppDisableSwipeBack", {});
}