import React from 'react';
import { Panel, Spinner, Link, Placeholder, Button, Div } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import GlobalResult from '../components/GlobalResult/GlobalResult';
import OneResult from '../components/OneResult/OneResult';

import { getScan } from '../func/back';
import { isKeyInObj } from '../func/utils';
import app from '../func/app';

import Icon56ErrorOutline from '@vkontakte/icons/dist/56/error_outline';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';

class Result extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { site } = this.props
		if (site) {
			getScan(site, (response) => {
				//const { total, valid, funcTime, domen } = response
				app.setState({
					result: {
						...response
					}
				})
			}, () => {
				app.setState((state) => ({
					result: {
						...state.result,
						error: false, //true
					}
				}))
			})
		}
	}

	getGlobalResultText = () => {
		const { result } = this.props
		const { domen } = result
		const resText = `У сайта ${domen} ${result.total} из 100 возможных баллов.\n\n`

		if (result.total < 30) {
			return  `${resText} Это низкий показатель, обязательно займитесь решением проблем сайта.`
		} else if (result.total >= 30 && result.total < 60) {
			return  `${resText} Удовлетворительный результат, стоит плотнее поработать над сайтом и устранением ошибок на нем.`
		} else if (result.total >= 60 && result.total < 85) {
			return  `${resText} Хороший результат, но есть, куда расти далее!`
		} else if (result.total >= 85) {
			return  `${resText} Отличный результат. Вы идете правильным направление, продолжайте развивать сайт.`
		} else {
			return resText
		}
	}

	getResultItems = () => {
		let answer = [];

		const { result } = this.props
		//const { valid } = result
		
		const availability = isKeyInObj(result, 'availability')
		const robots = isKeyInObj(result, 'robots')
		const source = isKeyInObj(result, 'source')
		const viewport = isKeyInObj(result, 'viewport')
		const images = isKeyInObj(result, 'images')
		const title = isKeyInObj(result, 'title')
		const favicon = isKeyInObj(result, 'favicon')
		const headText = isKeyInObj(result, 'headText')
		const links = isKeyInObj(result, 'links')
		const social_networks = isKeyInObj(result, 'social_networks')

		/* 
			Доступность сайта
		*/
		if (availability) {
			const { http, https, redirect } = availability
			answer.push(
				<OneResult
					key={1}
					title="Доступность"
					icon={(http && https && redirect) ? `V` : `X`}
					text={
							(http && https) ?
								<div>
									{ 
										(redirect) ? 
											`Сайт доступен по протоколу HTTPS, 301 редирект установлено корректно.` 
										: 
											`Сайт доступен по протоколу HTTPS и HTTP, но необходимо установить 301 редирект на версию сайта, которую Вы хотите иметь в поиске. В противном случае две версии сайта создают лишнюю копию сайта в поиске.` 
									}
								</div>
							: 
								<div>
									{
										(http && !https) ? 
											<div>
												<div>Сайт доступен по протоколу HTTP.</div> 
												<br/>
												<div>В некоторых браузерах сайт может подсвечиваться как небезопасный и отталкивать пользователей. Подробнее о том, почему стоит задуматься о переезде на HTTPS читайте тут <Link href="https://vk.com/expertim?w=wall-182571480_19" target="_blank">https://vk.com/expertim?w=wall-182571480_19</Link></div> 
												<br/>
												<div>Если будет принято решение перевести сайт на протокол HTTPS, следуйте инструкции по переезду тут <Link href="https://vk.com/expertim?w=wall-182571480_21" target="_blank">https://vk.com/expertim?w=wall-182571480_21</Link></div> 
											</div>
										: 
											null
									}
									{
										(!http && https) ? 
											<div>
												<div>Сайт доступен по протоколу HTTPS.</div> 
											</div>
										: 
											null
									}
								</div>
					}
				/>
			)
		}

		/* 
			Файл robots.txt
		*/
		if (robots) {
			const { file, sitemap, page } = robots
			answer.push(
				<OneResult
					key={2}
					title="robots.txt"
					icon={(file && sitemap && page) ? `V` : `X`}
					text={
							(file) ?
								<div>
									Файл robots.txt найден. { (sitemap) ? `Есть карта сайта в файле.` : `Для успешной индексации страниц подключите карту сайта.` } { (page) ? `Главная страница разрешена для индексации.` : `Главная страница запрещена для индексации.` }
								</div>
							: 
								<div>
									У сайта нет robots.txt
								</div>
					}
				/>
			)
		}

		/* 
			Время получения главной страницы сайта
		*/
		if (source) {
			const { time, page } = source
			answer.push(
				<OneResult
					key={3}
					title="Время получения главной страницы сайта"
					icon={(page) ? `V` : `X`}
					text={
							(page) ?
								<div>
									Нам удалось получить главную страницу сайта за {time} мс.
								</div>
							: 
								<div>
									К сожалению мы не смогли получить главную страницу сайта.
								</div>
					}
				/>
			)
		}

		/*
			Проверка тега viewport
		*/
		if (viewport) {
			const { value } = viewport
			answer.push(
				<OneResult
					key={4}
					title="Адаптивность сайта"
					icon={(value) ? `V` : `X`}
					text={
							(value) ?
								<div>
									На сайте есть тег viewport. Так держать!
								</div>
							: 
								<div>
									Мы не нашли тег viewport, проверьте адаптивен ли сайт для разных устройств.
								</div>
					}
				/>
			)
		}

		/*
			Проверка битых картинок на главной странице сайта
		*/
		if (images) {
			const { bad_images } = images
			answer.push(
				<OneResult
					key={5}
					title="Битые картинки"
					icon={(bad_images.length > 0) ? `X` : `V`}
					text={
							(bad_images.length > 0) ?
								<div>
									На главной странице сайта есть битые картинки.
									<br/><br/>
									<div>Ссылки на них: </div>
									{
										bad_images.map((item, i) => {
											return (
												<div key={`image_${i}`}>
													<Link target="_blank" href={item}>{item}</Link>
												</div>
												
											)
										})
									}
								</div>
							: 
								<div>
									На главной странице сайта нет битых картинок. Супер!
								</div>
					}
				/>
			)
		}

		/*
			Проверка тега title
		*/
		if (title) {
			const { count, valid, text } = title
			answer.push(
				<OneResult
					key={6}
					title="Проверка тега title"
					icon={(count > 0 && valid) ? `V` : `X`}
					text={
							(count > 0) ?
								<div>
									{
										/*
											На главной странице сайта мы нашли {count} {declOfNum(count, ['заголовок','заголовока','заголовоков'])}. 
										*/
									}
									{ 
										(valid) ? 
											`Title – заголовок сайта в выдаче поисковой системы. Длина заголовка на сайте удовлетворяет критериям поисковых систем.` 
										: 
											`Title – заголовок сайта в выдаче поисковой системы. Его оптимальная длина от 60 до 80 символов. При большем размере он будет подрезан. Избегайте перенасыщенности Title ключевыми словами.` 
									}
									<br/><br/>
									{
										(text) ?
											<div>Заголовок: {text.replace(/&quot;/g, '"')}</div>
										: null
									}
								</div>
							: 
								<div>
									Мы не нашли заголовок на главной странице сайта.
								</div>
					}
				/>
			)
		}

		/*
			Проверка Favicon
		*/
		if (favicon) {
			const { value } = favicon
			answer.push(
				<OneResult
					key={7}
					title="Проверка Favicon"
					icon={(value) ? `V` : `X`}
					text={
							(value) ?
								<div>
									Отлично. у вас на главной странице есть favicon.
								</div>
							: 
								<div>
									Мы не нашли favicon на главной странице сайта.
								</div>
					}
				/>
			)
		}
		
		/*
			Проверка заголовков
		*/
		if (headText) {
			const { value, isFirstH1, isOneH1, valid, recomendation, noH1 } = headText
			answer.push(
				<OneResult
					key={8}
					title="Проверка заголовков"
					icon={(value && isFirstH1 && isOneH1 && valid) ? `V` : `X`}
					text={
							(value) ?
								<div>
									<div>{`Отлично. Вы используете заголовки. `} {(isFirstH1) ? `Заголовок h1 стоит первым. Это хорошо! ` : (noH1) ? `Заголовок h1 не найден Это не верно, надо исправить. Сделайте его в единственном экземпляре и первым на странице. ` : `Заголовок h1 стоит не первым. Заголовок h1 должен стоять выше всех остальных заголовков. `}</div>
									<div>
										{(isOneH1) ? <div><br/>Заголовок h1 один на странице. Это хорошо!</div> : (noH1) ? null : <div><br/>Заголовок h1 не один на странице. Сделайте его в единственном экземпляре. </div>}
									</div>
									<br/>
									<div>
									{
										(valid) ? 
											`Все остальные заголовки расставлены верно.` 
										: 
											<div>
												Остальные заголовки расставлены не верно. Проверьте соблюдение иерархии заголовков h1-h6 { (recomendation) ? <div><br/><br/>{recomendation}</div> : null } 
											</div>
									}
									</div>
								</div>
							: 
								<div>
									Похоже, что вы не используете заголовки вообще.
								</div>
					}
				/>
			)
		}
		
		/*
			Битые ссылки
		*/
		if (links) {
			const { bad_links } = links
			answer.push(
				<OneResult
					key={9}
					title="Битые ссылки"
					icon={(bad_links.length > 0) ? `X` : `V`}
					text={
							(bad_links.length > 0) ?
								<div>
									На главной странице сайта есть битые ссылки.
									<br/><br/>
									<div>Ссылки: </div>
									{
										bad_links.map((item, i) => {
											return (
												<div key={`link_${i}`}>
													<Link target="_blank" href={item}>{item}</Link><br/>
												</div>
											)
										})
									}
								</div>
							: 
								<div>
									На главной странице сайта нет битых ссылок. Супер!
								</div>
					}
				/>
			)
		}

		/*
			Социальные сети
		*/
		if (social_networks) {
			const { social } = social_networks
			const { vk, fb, tw, ok } = social
			answer.push(
				<OneResult
					key={10}
					title="Социальные сети"
					icon={(vk || fb || tw || social.in || ok) ? `V` : `X`}
					text={
						<div>
							<div>Социальные сети – это миллионы людей, которые могут положительно относиться к сайту и тем самым повышать его поведенческие факторы, что положительно сказывается на позициях в поисковых системах.</div>
							<br/>
							{
								(vk) ?
									<div className="flex_start"><Icon16Done className="pr-5"/>ВКонтакте – Сообщество Вконтакте найдено <Link className="pl-5" target="_blank" href={vk}>{vk}</Link></div>
								: 
									<div className="flex_start"><Icon16Cancel className="pr-5"/>ВКонтакте – Сообщество ВКонтакте не найдено</div>
							}
							{
								(fb) ?
									<div className="flex_start"><Icon16Done className="pr-5"/>Facebook – Сообщество Facebook найдено <Link className="pl-5" target="_blank" href={fb}>{fb}</Link></div>
								: 
									<div className="flex_start"><Icon16Cancel className="pr-5"/>Facebook – Сообщество Facebook не найдено</div>
							}
							{
								(tw) ?
									<div className="flex_start"><Icon16Done className="pr-5"/>Twitter – Сообщество Twitter найдено <Link className="pl-5" target="_blank" href={tw}>{tw}</Link></div>
								: 
									<div className="flex_start"><Icon16Cancel className="pr-5"/>Twitter – Сообщество Twitter не найдено</div>
							}
							{
								(social.in) ?
									<div className="flex_start"><Icon16Done className="pr-5"/>Instagram – Сообщество Instagram найдено <Link className="pl-5" target="_blank" href={social.in}>{social.in}</Link></div>
								: 
									<div className="flex_start"><Icon16Cancel className="pr-5"/>Instagram – Сообщество Instagram не найдено</div>
							}
							{
								(ok) ?
									<div className="flex_start"><Icon16Done className="pr-5"/>Instagramm – Сообщество Одноклассники найдено <Link className="pl-5" target="_blank" href={ok}>{ok}</Link></div>
								: 
									<div className="flex_start"><Icon16Cancel className="pr-5"/>Одноклассники – Сообщество Одноклассники не найдено</div>
							}
						</div>
					}
				/>
			)
		}

		return answer
	}

	render() {
		const { id, navigator, site, result } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Результат"/>
				{
					(site && result) ?
						(!result.valid) ? 
								<Placeholder
									stretched={true}
									icon={<Icon56ErrorOutline />}
									title="Ошибка!"
									action={
										<Button 
											size='l' 
											onClick={navigator.goPanelBack}
										>
											Назад
										</Button>
									}
								>
									К сожалению, проверяемый сайт не доступен.
								</Placeholder>
						: 
							<div>
								<GlobalResult
									text={this.getGlobalResultText()}
								/>

								{ this.getResultItems() }
							</div>
					: 
						<div>
							<Spinner size="regular" style={{ marginTop: 20 }} />
							<Div className="flex_center">Подождите, мы анализируем сайт...</Div>
						</div>
				}
			</Panel>
		)
	}

}

export default Result;