import React from 'react';
import { Div } from '@vkontakte/vkui';
import './style.css';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import Icon24Error from '@vkontakte/icons/dist/24/error';

class OneResult extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { text, title, icon } = this.props
		return (
			<Div>
				<div className='__outlineBlock'>
					<div className="__oneResult_title pb-10 flex_start">{(icon === 'V' ? <Icon24Done fill="#5bb309" className="pr-5"/> : <Icon24Error fill="var(--destructive)" className="pr-5"/>) } {title}</div>
					<div>{text}</div>
				</div>
			</Div>
		)
	}
}

export default OneResult;