import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/promise'
import 'core-js/es6/symbol'
import 'core-js/es6/object'
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vk-connect';
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper';
import App from './App';
import BadVersion from './components/BadVersion/BadVersion';
// import registerServiceWorker from './sw';
import { getParams, isDeviceSupported } from './func/utils';
import { startEruda } from './func/eruda';

//css
import './style.css';

// Init VK App
connect.send('VKWebAppInit', {});

// Init Eruda Console
startEruda()

// Если вы хотите, чтобы ваше веб-приложение работало в оффлайне и загружалось быстрее,
// расскомментируйте строку с registerServiceWorker();
// Но не забывайте, что на данный момент у технологии есть достаточно подводных камней
// Подробнее про сервис воркеры можно почитать тут — https://vk.cc/8MHpmT 
// registerServiceWorker();

// Корневой элемент приложения
const root = document.getElementById('root');

switch (getParams().vk_platform) {
	case 'mobile_web':
		mVKMiniAppsScrollHelper(root);
		ReactDOM.render(<App />, root);
	break;
	case 'desktop_web':
		ReactDOM.render(<App />, root);
	break;
	default:
		if (isDeviceSupported()) {
			ReactDOM.render(<App />, root);
		} else {
			ReactDOM.render(<BadVersion />, root);
		}
}