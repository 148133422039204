import React from 'react';
import { Div } from '@vkontakte/vkui';
import TextBlock from '../Text/Text';
import './style.css';

class GlobalResult extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { text } = this.props
		return (
			<Div className="pb-0">
				<div className='__outlineBlock'>
					<TextBlock show={true} showMoreButton={false} length={1000}>
						{text}
					</TextBlock>
				</div>
			</Div>
		)
	}
}

export default GlobalResult;