import React from 'react';
import { ScreenSpinner, ConfigProvider } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Stack from './components/Navigator/Stack';
import Page from './components/Navigator/Page';

//хелперы
import app from './func/app';

import { go } from './func/init';
import { devLog } from './func/utils';

//стартовые панели
import Home from './panels/Home';

//Welcome Panel
import Welcome from './panels/Welcome/Welcome';

//HomeComponents
import Result from './panels/Result';


class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isAdminInGroup: false, //пользователь администратор в сообществе
			isWebView: false, //isWebView

			goStartPanel: false, //показывать стартовый экран

			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи
			apiGroupsData: [], //информация о группах из апи

			siteUrl: "", //URL проверяемого сайта
			site: "", // Очищенный домен сайта
			result: false, // результат тестирования false
		};
	}

	componentDidMount() {
		app.setState = (state, callback) => { this.setState(state, callback) }
		go()
	}

	render() {
		devLog(this.state)
		const { isLoad, popout, header, scheme, goStartPanel, isWebView, siteUrl, site, result } = this.state

		if (!isLoad) { //Показываем спиннер
			return <ScreenSpinner/>
		}

		if (isLoad) { //сервис загружен
			return (
				<ConfigProvider scheme={scheme} isWebView={isWebView} webviewType="vkapps">
					<Stack id="main" activePage="scaner" isEpic={false}>
						<Page id="scaner" activePanel="Home" header={(!goStartPanel) ? header : false} popout={popout}>
							<Welcome 
								id={(goStartPanel) ? `Home` : `Welcome`}
							/>

							<Home 
								id={(!goStartPanel) ? `Home` : `Welcome`}
								siteUrl={siteUrl}
							/>

							<Result 
								id="Result"
								site={site}
								result={result}
							/>
							
						</Page>
					</Stack>
				</ConfigProvider>
			);
		}
	}
}

export default App;