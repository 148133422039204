import React from 'react';
import { Panel, Button, Gallery, withPlatform, Spinner, Placeholder } from '@vkontakte/vkui';

import { startApp } from '../../func/init';

import Icon56MessageReadOutline from '@vkontakte/icons/dist/56/message_read_outline';

import './style.css';

class Welcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slideIndex: 0,
			wait: false,
		};
	}

	renderButton = (slideIndex) => {
		switch (slideIndex) {
			case 0:
				return (
					<Button 
						size='l' 
						onClick={this.startPril}
					>
						Отлично
					</Button>
				)
			default:
				//
		}
	}

	startPril = () => {
		this.setState({
			wait: true
		}, () => {
			startApp(false, true)
		})
	}

	render() {
		const { wait } = this.state
		const { id } = this.props
		return (
			<Panel theme="white" id={id}>
				{
					(wait) ?
						<Spinner size="regular" style={{ marginTop: 20 }} />
					: 
							<Gallery
								slideWidth="100%"
								align="center"
								className="WelcomeGallery"
								slideIndex={1}
								onChange={slideIndex => this.setState({slideIndex})}
								autoplay={0}
								bullets={false}
							>

								<Placeholder
									stretched={true}
									icon={<Icon56MessageReadOutline />}
									title="Сервис для анализа сайтов"
									action={this.renderButton(0)}
								>
									Поможет проверить ваш сайт на частые ошибки SEO
								</Placeholder>

							</Gallery>	
				}

			</Panel>
		)
	}
}

export default withPlatform(Welcome);