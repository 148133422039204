import React from 'react';
import { Panel, Button, Div, Placeholder } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import Input from '../components/Input/Input';
import { webAppOpenQR } from '../func/vk';

import config from '../config';
import app from '../func/app';
import { showErrorMessage } from '../func/alert';

import Icon24Qr from '@vkontakte/icons/dist/24/qr';

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		window.onkeypress = (e) => {
			 if (e.keyCode === 13) {
			 	this.goResult()
			 }
		}
	}

	/*
		Открывает QR
	*/
	openQR = () => {
		webAppOpenQR((resposne) => {
			const { qr_data } = resposne
			app.setState({ siteUrl: qr_data }, this.goResult);
		})
	}

	/*
		onChange для Input
	*/
	onChange = (e) => {
		const { value } = e.currentTarget;
		app.setState({
			siteUrl: value.substr(0, 300),
		});
	}

	/*
		Переходим на панель сканера
	*/
	goResult = () => {
		const { navigator, siteUrl } = this.props
		const site = this.isDomen(siteUrl) //проверка домена

		if (site) {
			app.setState({
				site:site,
				result: false,
			}, () => {
				navigator.goPanel('Result')
			})
		}
	}

	/*
		Проверяем строку, достаём домен или пишем ошибку
	*/
	isDomen = (siteUrl) => {
		if (siteUrl) {
			return siteUrl
		} else {
			showErrorMessage('Ошибка!','Адрес сайта не может быть пустым')
		}
	}

	render() {
		const { id, siteUrl } = this.props
		const { vk_platform } = config.params
		return (
			<Panel theme="white" id={id}>
				<Header name="Анализ сайта"/>
				<Placeholder className="w-100" stretched={true}>
						<Input 
							onChange={this.onChange}
							value={siteUrl}
							placeholder={(vk_platform === 'mobile_android' || vk_platform === 'mobile_iphone') ? `Введите/отсканируйте адрес сайта` : `Введите адрес сайта`}
							buttons={
								(vk_platform === 'mobile_android' || vk_platform === 'mobile_iphone') ?
									<div className="flex_center">
										<Icon24Qr onClick={this.openQR} fill="var(--button_primary_background)"/>
									</div>
								: 
									null
							}
						/>
					<Div className="flex_center">
						<Button onClick={this.goResult}>Анализировать</Button>
					</Div>
				</Placeholder>
			</Panel>
		)
	}
}

export default Home;