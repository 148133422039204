import React from 'react';
import { Input as InputVKUI } from '@vkontakte/vkui';
import './style.css';

class Input extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let { className, buttons, placeholder, value, onChange } = this.props
		return (
			<div className={`${className} __inputBlock`}>
				<div className={`__inputControls`}>
					{buttons}
				</div>
				<InputVKUI onChange={onChange} value={value} placeholder={placeholder} type="text"/>
			</div>
		)
	}
}

export default Input;