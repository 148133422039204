import app from './app';
import config from '../config';
import { getParams, getHash, isKeyInObj, setLocalStorage, getLocalStorage } from './utils';
import { getStartToken, listenVKWebAppUpdateConfig, listenVKWebAppViewRestore, isWebView } from './vk';

export function go(callback) {

	//событие смены темы
	listenVKWebAppUpdateConfig((data) => {
		const scheme = data.scheme ? data.scheme : 'client_light';
		app.setState({
			scheme: scheme
		})
	})

	//событие возобновления из кеша
	listenVKWebAppViewRestore(() => {
		app.setState({
			isLoad: false, //если false, то приложение перерендерится заново
		}, () => {
			_start()
		})
	})

	_start()
}

function _start() {
	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		getStartToken(params.vk_access_token_settings.split(','), (token) => {
			startApp(token, false)
		})
	})
}

export function startApp(token, ignoreStartPanel = false) {

	const { hash } = config
	const params = { //дополнительные параметры для функции firstQuery, чтобы получить нужные данные
		vk_group_id: (isKeyInObj(hash, 'g')) ? +hash.g : (isStartInGroup()) ? +config.params.vk_group_id : false,
		ignoreStartPanel: ignoreStartPanel
	}

	config.set({
		access_token: (token) ? token.access_token : config.access_token, 
	}, () => {

		firstQuery(params, (token) ? token.access_token : config.access_token, (response) => {
			app.setState((state, props) => ({
				userScope: (token) ? token.scope.split(',') : state.userScope,
				goStartPanel: (ignoreStartPanel) ? false : isStartPanel(),
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true,
				isStartInGroup: (params.vk_group_id) ? true : isStartInGroup(),
				isAdminInGroup: isAdminInGroup(),
				isWebView: isWebView(),
				...response
			}), () => {
				if (ignoreStartPanel) {
					setLocalStorage('welcome', 'true')
				}
			})
		})

	})
	
}

function firstQuery(params = {}, access_token = '', callback = () => {}) {
	//тут все запросы к беку и все запросы к апи вк
	const { ignoreStartPanel } = params
	const goStartPanel = (ignoreStartPanel) ? false : isStartPanel()

	if (!goStartPanel) {
		//получаем данные с бекенда
		callback({
			//content:["test","test","test"]
		})
	} else {
		//не получаем данные с бекенда
		callback({})
	}
}

function isAdminInGroup() {
	return (config.params.vk_viewer_group_role === 'admin') ? true : false
}

function isStartPanel() {
	//проверить localstorage
	const welcome = getLocalStorage('welcome')
	if (!welcome && config.startPanel) {
		return true
	} else {
		return false
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}