import config from '../config';
import 'whatwg-fetch'
import { showErrorMessage } from '../func/alert';

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.backUrl
	json_data.params = config.params
	
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function sentWss(connection, json_data) {
	json_data.params = config.params
	connection.send(JSON.stringify(json_data));
}

export function getScan(site, callback = () => {}, errorCallback = () => {}) {
	sentReq({command: "getScan", site:site}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage('Ошибка!', response.text_error)
			errorCallback()
		}
	}, (error) => {
		showErrorMessage('Ошибка интернета!', 'Проверьте соединение с интернетом.')
		console.log(error)
		errorCallback()
	})
}

export function firstQuery(callback = () => {}) {
	//тут первые запросы к апи
	callback(true)
}
